import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import React, { Component } from 'react';
import { FormattedMessage, IntlContextConsumer } from 'gatsby-plugin-react-intl';
import { MdArrowBack, MdArrowForward, MdClose, MdShoppingCart } from 'react-icons/md';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { keyframes } from '@emotion/react';

import Button from '../shared/Buttons';
import CartIndicator from './CartIndicator';
import CartList from './CartList';
import EmptyCart from './EmptyCart';
import ShippingInfo from './ShippingInfo';
import StoreContext from '../../context/StoreContext';
import { breakpoints, colors, dimensions, spacing } from '../../utils/styles';

const CartRoot = styled(`div`)`
  background: ${colors.lightest};
  bottom: 0;
  position: fixed;
  right: 0;
  top: -1px;
  transform: translateX(100%);
  transition: transform 0.75s;
  width: 100%;
  will-change: transform;
  z-index: 1002;

  &.open {
    transform: translateX(0%);
  }

  &.closed {
    transform: translateX(100%);
  }

  ::after {
    background-color: ${colors.lightest};
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 250ms;
  }

  &.loading {
    ::after {
      opacity: 0.9;
      pointer-events: all;
    }
  }

  @media (min-width: ${breakpoints.desktop}px) {
    width: ${dimensions.cartWidthDesktop};

    &.covered {
      display: none;
    }
  }
`;

const Heading = styled(`header`)`
  align-items: center;
  display: flex;
  height: ${dimensions.headerHeight};
  justify-content: flex-start;
`;

const Title = styled(`h2`)`
  flex: 1 0 16rem;
  margin-left: ${spacing.md}px;
  position: relative;

  @media (min-width: ${breakpoints.desktop}px) {
    left: -60px;
  }
`;

const Content = styled(`div`)`
  bottom: 0;
  overflow-y: auto;
  padding: ${spacing.md}px;
  position: absolute;
  top: ${dimensions.headerHeight};
  width: 100%;

  @media (min-width: ${breakpoints.desktop}px) {
    ::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.brandBright};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${colors.lilac};
    }
    ::-webkit-scrollbar-track {
      background: ${colors.brandLight};
    }
  }
`;

const ItemsNumber = styled(`span`)`
  align-items: center;
  background: ${colors.lemon};
  border-radius: 50%;
  color: ${colors.brandDark};
  display: flex;
  font-weight: bold;
  height: 36px;
  justify-content: center;
  width: 36px;
`;

const ItemsInCart = styled(`div`)`
  align-items: center;
  display: flex;
  line-height: 1.2;
  text-align: right;

  ${ItemsNumber} {
    margin-left: ${spacing.xs}px;
    margin-right: ${spacing.md}px;
  }
`;

const Costs = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.sm}px;
`;

const Cost = styled(`div`)`
  display: flex;
  padding: 0 ${spacing.xs}px ${spacing['2xs']}px;

  :last-child {
    padding-bottom: 0;
  }

  span {
    color: ${colors.textMild};
    flex-basis: 60%;
    text-align: right;
  }

  strong {
    color: ${colors.lilac};
    flex-basis: 40%;
    text-align: right;
  }
`;

const Total = styled(Cost)`
  border-top: 1px solid ${colors.brandBright};
  color: ${colors.brandDark};
  margin-top: ${spacing.xs}px;
  padding-top: ${spacing.sm}px;

  span {
    font-weight: bold;
    text-transform: uppercase;
  }

  strong,
  span {
    color: inherit;
  }
`;

const iconEntry = keyframes`
  0%, 50% {
    transform: scale(0)
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const numberEntry = keyframes`
  0%{
    transform: scale(0)
  }
  90% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(0.6);
  }
`;

const CartToggle = styled(Button)`
  display: flex;
  height: ${dimensions.headerHeight};
  justify-content: center;
  left: 0;
  padding: 0;
  position: relative;
  top: 0;
  transform: translateX(-100%);
  transition: all 0.5s ease;
  width: ${dimensions.headerHeight};

  :focus {
    box-shadow: 0 0 0 1px ${colors.accent} inset;
  }

  .open & {
    background: ${colors.lilac};
    color: ${colors.lightest};
    transform: translateX(0);
  }

  @media (min-width: ${breakpoints.desktop}px) {
    .open & {
      transform: translateX(-100%);
    }
  }

  svg {
    animation: ${iconEntry} 0.75s ease forwards;
    height: 28px;
    margin: 0;
    width: 28px;
  }

  ${ItemsNumber} {
    animation: ${numberEntry} 0.5s ease forwards;
    position: absolute;
    right: ${spacing['3xs']}px;
    top: ${spacing['3xs']}px;
    transform: scale(0.6);
  }
`;

const BackLink = styled(Button)`
  margin-bottom: ${spacing.sm}px;
  width: 100%;
`;

class Cart extends Component {
  state = {
    className: 'closed',
    isLoading: false,
  };

  componentDidUpdate(prevProps) {
    const componentStatusChanged = prevProps.status !== this.props.status;
    const imageBrowserStatusChanged =
      this.props.productImagesBrowserStatus !== prevProps.productImagesBrowserStatus;

    if (componentStatusChanged) {
      this.setState({
        className: this.props.status,
      });
    }

    if (this.props.isDesktopViewport) {
      if (imageBrowserStatusChanged) {
        if (this.props.productImagesBrowserStatus === 'open') {
          setTimeout(() => {
            this.setState((state) => ({
              className: state.className + ' covered',
            }));
          }, 500);
        } else {
          this.setState((state) => ({
            className: state.className.replace('covered', ''),
          }));
        }
      }
    }
  }

  render() {
    const { status, toggle } = this.props;
    const { className } = this.state;

    return (
      <StoreContext.Consumer>
        {({ client, checkout, removeLineItem, updateLineItem, adding }) => {
          const setCartLoading = (bool) => this.setState({ isLoading: bool });

          const handleRemove = (itemID) => async (event) => {
            event.preventDefault();
            await removeLineItem(client, checkout.id, itemID);
            setCartLoading(false);
          };

          const handleQuantityChange = (lineItemID) => async (quantity) => {
            if (!quantity) {
              return;
            }
            await updateLineItem(client, checkout.id, lineItemID, quantity);
            setCartLoading(false);
          };

          const itemsInCart = checkout.lineItems.reduce((total, item) => total + item.quantity, 0);

          const hasFreeShipping = (subtotalPrice) => parseInt(subtotalPrice, 10) > 800;

          return (
            <CartRoot className={`${className} ${this.state.isLoading ? 'loading' : ''}`}>
              <Heading>
                <CartToggle
                  aria-label={`Shopping cart with ${itemsInCart} items`}
                  onClick={toggle}
                  sx={{ bg: 'background', color: 'primary', borderColor: 'background' }}
                >
                  {status === 'open' ? (
                    <MdClose />
                  ) : (
                    <React.Fragment>
                      <MdShoppingCart />
                      {itemsInCart > 0 && <ItemsNumber>{itemsInCart}</ItemsNumber>}
                    </React.Fragment>
                  )}
                </CartToggle>
                <CartIndicator itemsInCart={itemsInCart} adding={adding} />
                <Title>
                  <FormattedMessage id="cart.yourCart" />
                </Title>
                <ItemsInCart>
                  <FormattedMessage id="cart.itemsInCart" />
                  <ItemsNumber>{itemsInCart}</ItemsNumber>
                </ItemsInCart>
              </Heading>
              {checkout.lineItems.length > 0 ? (
                <Content>
                  <CartList
                    items={checkout.lineItems}
                    handleRemove={handleRemove}
                    updateQuantity={handleQuantityChange}
                    setCartLoading={setCartLoading}
                    isCartLoading={this.state.isLoading}
                  />

                  <Costs>
                    <Cost>
                      <FormattedMessage id="cart.subtotal" tagName="span" />
                      <strong>
                        <FormattedMessage id="hkd" /> ${checkout.subtotalPrice.amount}
                      </strong>
                    </Cost>
                    <Cost>
                      <FormattedMessage id="cart.taxes" tagName="span" />
                      <strong>{checkout.totalTax.amount}</strong>
                    </Cost>
                    <Cost>
                      <FormattedMessage id="cart.shipping" tagName="span" />
                      {hasFreeShipping(checkout.subtotalPrice.amount) ? (
                        <FormattedMessage id="cart.free" tagName="strong" />
                      ) : (
                        <FormattedMessage id="cart.tbd" tagName="strong" />
                      )}
                    </Cost>
                    <Total>
                      <FormattedMessage id="cart.totalPrice" tagName="span" />
                      <strong>
                        {hasFreeShipping(checkout.subtotalPrice.amount) ? (
                          <React.Fragment>
                            <FormattedMessage id="hkd" /> ${checkout.totalPrice.amount}
                          </React.Fragment>
                        ) : (
                          <FormattedMessage id="cart.tbd" />
                        )}
                      </strong>
                    </Total>
                  </Costs>

                  <IntlContextConsumer>
                    {({ language }) => (
                      <Button
                        href={`${checkout.webUrl}&locale=${language === 'zh' ? 'zh-TW' : 'en'}`}
                        sx={{ width: '100%', mb: 1, mt: 3 }}
                      >
                        <FormattedMessage id="cart.checkout" />
                        <MdArrowForward />
                      </Button>
                    )}
                  </IntlContextConsumer>
                  <BackLink onClick={toggle}>
                    <MdArrowBack />
                    <FormattedMessage id="cart.backToShopping" />
                  </BackLink>

                  <ShippingInfo />
                </Content>
              ) : (
                <EmptyCart />
              )}
            </CartRoot>
          );
        }}
      </StoreContext.Consumer>
    );
  }
}

Cart.propTypes = {
  status: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  isDesktopViewport: PropTypes.bool,
  productImagesBrowserStatus: PropTypes.string,
};

export default Cart;
